.code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #FFFFFF;
}

.code-heading {
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    text-align: center;
    color: #343338;
    margin-bottom: 20px;
}


.otp-input > div:first-child{
    display: flex;
    justify-content: center;
}

.otp-input input {
    background-color: transparent;
    width: 50px !important;
    height: 60px;
    color: #343338;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-right: 10px;
    background: #E2E9F0;
    border: none !important ;
    outline: 0;
border-radius: 2px;
}

.otp-input{
    margin-bottom: 30px;
}

.otp-input input:focus-visible{
    border: 1px solid #4B4A4E !important;
}

.custom-button{    
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
    background: #0056D2;
    height: 50px;
    width: 170px;
    border-radius: 0;
}

.custom-button:focus-visible {
    color: #FFFFFF !important;
    background-color: #0056D2!important;
    border-color: #0056D2 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.custom-button.hover{
    color: #FFFFFF !important;
    background-color: #0056D2!important;
    border-color: #0056D2 !important;
}
.code-error-message{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */
    text-align: center;
   color: #BE2222;
    margin-bottom: 50px;
}


.code-header{
    font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 145%;
/* identical to box height, or 29px */
text-align: center;
color: #1E1F20;
margin-bottom: 20px;
}

.code-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}