@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");


:root {
  --primary: #415464;
  --dark: #415464;
  --lightDark: #607180;  
  --white: #ffffff;
  --light: #f1f1f1;
  --purple: #9747FF;
  --text: #3B3B3B;
  --whiteBackground: #FAFAFA;
  --secondary: #2456CA;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.primary-color {
  color: #415464;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #EBEBEB;
}

::-webkit-scrollbar-thumb {
  background: #607180;
}

::-webkit-scrollbar-thumb:hover {
  background: #415464;
}

.header-color {
  color: var(--text)
}

.subText-color {
  color: var(--dark)
}

.white-text {
  color: var(--white)
}

.secondary-color {
  color: var(--secondary)
}

.content-bgrd {
  background-color: var(--secondary);
}

.content-bgrd:hover:active:focus {
  background-color: var(--secondary);
}

.background-color {
  background: var(--whiteBackground);
}

.border-color {
  border-color: var(--dark);
}

